import { get, put } from "../api_helper"
import * as url from "../url_helper"

const getUsers = async (includeSelf = false) => {
  const response = await get(url.GET_USERS, {
    params: { include_self: includeSelf }
  })

  return { data: response }
}

const getUserDetail = async userId => {
  const response = await get(`${url.GET_USERS}/${userId}`)
  return { data: response }
}

const updateUser = async user => {
  const response = await put(`${url.PUT_USERS}`, user)
  return { data: response }
}

export { getUsers, getUserDetail, updateUser }

import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchDeliveryPlansRequest } from "../../store/deliveryPlan/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

const DeliveryPlanSelect = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Reduxからのロード状態/配送計画一覧
  const isLoadingPlans = useSelector(state => state.deliveryPlanReducer.loading)
  const deliveryPlans = useSelector(state => state.deliveryPlanReducer.deliveryPlans) || []

  // ★★★ ローカルステート: 各計画に confirmedフラグを追加して保持 ★★★
  const [localPlans, setLocalPlans] = useState([])

  // コンポーネント初期表示時に配信計画を取得
  useEffect(() => {
    dispatch(fetchDeliveryPlansRequest({ type: 2 }))
  }, [dispatch])

  // ReduxのdeliveryPlansが更新されたら、ローカルステートにコピー
  // ここで、既にconfirmed状態を別途保存している場合は合わせて反映できます
  useEffect(() => {
    // まだconfirmed状態を持っていない場合は false で初期化
    const plansWithConfirm = deliveryPlans.map(plan => ({
      ...plan,
      confirmed: plan.confirmed ?? false, // すでにplan.confirmedがあればそれを使う
    }))
    setLocalPlans(plansWithConfirm)
  }, [deliveryPlans])

  // 「確認」ボタン押下: 対象の計画だけconfirmedをtrueにする
  const handleConfirm = planId => {
    setLocalPlans(prevPlans =>
      prevPlans.map(p =>
        p.id === planId
          ? { ...p, confirmed: true, status_name: "確認済み" }
          : p
      )
    )
  }

  // 「実行」ボタン押下: 既存の処理を実行
  const handleSelectPlan = data => {
    let plan = data
    let path
    switch (plan.status) {
      case 1:
        path = "/delivery-plan/confirm"
        navigate(path, {
          state: {
            startLocationName: data.start_warehouse_name,
            deliveryPlan: data,
            history: null,
          },
        })
        break
      case 2:
        path = "/delivery-plan/vehicle-select"
        navigate(path, {
          state: {
            startLocationName: data.start_warehouse_name,
            deliveryPlan: data,
            history: null,
          },
        })
        break
      case 3:
      case 4:
        path = "/delivery-plan/execute"
        navigate(path, {
          state: {
            deliveryPlan: data,
          },
        })
        break
      default:
        console.error("Unknown status:", plan.status)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="ホーム" breadcrumbItem="配送計画選択" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoadingPlans} />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h2>配送計画一覧</h2>
                  {localPlans.length > 0 ? (
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>計画No</th>
                          <th>補充担当者</th>
                          <th>ステータス</th>
                          <th>操作</th>
                          <th>確認</th>
                        </tr>
                      </thead>
                      <tbody>
                        {localPlans.map((plan, index) => (
                          <tr key={plan.id}>
                            <th scope="row">{index + 1}</th>
                            <td>{plan.id}</td>
                            <td>{plan.user_name}</td>
                            <td>{plan.status_name}</td>
                            <td>
                              <Button
                                color="primary"
                                onClick={() => handleSelectPlan(plan)}
                              >
                                実行
                              </Button>
                            </td>
                            <td>
                              {plan.confirmed ? (
                                <span>確認済み</span>
                              ) : (
                                <Button color="warning" onClick={() => handleConfirm(plan.id)}>
                                  確認
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>割り当てられた配信計画がありません。</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DeliveryPlanSelect

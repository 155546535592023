import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_USERS_DATA,
  FETCH_USERS_IPC_DATA,
  ADD_IPC_TO_USER,
  REMOVE_IPC_FROM_USER,
  FETCH_USER_DETAIL,
  UPDATE_USER,
} from "./actionTypes"

import {
  fetchUserDetailSuccess,
  fetchUserDetailFailure,
  fetchUsersDataSuccess,
  fetchUsersDataError,
  fetchUsersIPCDataSuccess,
  fetchUsersIPCDataError,
  addIpcToUserSuccess,
  addIpcToUserError,
  removeIpcFromUserSuccess,
  removeIpcFromUserError,
  updateUserSuccess,
  updateUserError,
} from "./actions"

import {
  getUsers,
  getIpcs,
  putIpcs,
  deleteIpcs,
  getUserDetail,
  updateUser,
  getIpcsForUser,
} from "../../helpers/backend_helper"

function* fetchUsersDataSaga(action) {
  try {
    const response = yield call(getUsers, action.payload?.includeSelf)
    yield put(fetchUsersDataSuccess(response))
  } catch (error) {
    yield put(fetchUsersDataError(error))
  }
}

function* fetchUsersIPCDataSaga(action) {
  try {
    const { user_id } = action.payload
    const ipcsData = yield call(getIpcsForUser, user_id)
    yield put(fetchUsersIPCDataSuccess(ipcsData))
  } catch (error) {
    yield put(fetchUsersIPCDataError(error))
  }
}

function* addIpcToUserSaga(action) {
  try {
    const { userId, ipcNo } = action.payload
    const ipcsData = yield call(putIpcs, userId, ipcNo)
    yield put(addIpcToUserSuccess(ipcsData))

    yield put(fetchUsersIPCDataSuccess(ipcsData))
  } catch (error) {
    yield put(addIpcToUserError(error))
  }
}

function* removeIpcFromUserSaga(action) {
  try {
    const { userId, ipcNo } = action.payload
    const ipcsData = yield call(deleteIpcs, userId, ipcNo)
    yield put(removeIpcFromUserSuccess(ipcsData))

    yield put(fetchUsersIPCDataSuccess(ipcsData))
  } catch (error) {
    yield put(removeIpcFromUserError(error))
  }
}

function* fetchUserDetailSaga(action) {
  try {
    const userId = action.payload
    const userData = yield call(getUserDetail, userId)
    yield put(fetchUserDetailSuccess(userData.data.result))
  } catch (error) {
    yield put(fetchUserDetailFailure(error))
  }
}

function* updateUserSaga(action) {
  try {
    const updatedUserData = yield call(updateUser, action.payload)
    yield put(updateUserSuccess(updatedUserData))
  } catch (error) {
    yield put(updateUserError(error))
  }
}

function* UsersSaga() {
  yield takeEvery(FETCH_USERS_DATA, fetchUsersDataSaga)
  yield takeEvery(FETCH_USERS_IPC_DATA, fetchUsersIPCDataSaga)
  yield takeEvery(ADD_IPC_TO_USER, addIpcToUserSaga)
  yield takeEvery(REMOVE_IPC_FROM_USER, removeIpcFromUserSaga)
  yield takeEvery(FETCH_USER_DETAIL, fetchUserDetailSaga)
  yield takeEvery(UPDATE_USER, updateUserSaga)
}

export default UsersSaga

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label } from 'reactstrap';
import { addVehicleStock } from '../../../store/vehicle/actions';
import { fetchWarehouseStock } from '../../../store/warehouse/actions';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const AddItemVehicleStockModal = ({ isOpen, toggle, selectedDeliveryVehicleId }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState('');
  const [selectedGoodId, setSelectedGoodId] = useState('');
  // APIから取得した初期在庫数
  const [initialWarehouseQuantity, setInitialWarehouseQuantity] = useState(0);
  // 配送車へ移動する数量
  const [vehicleQuantity, setVehicleQuantity] = useState(0);

  const warehouses = useSelector(state => state.warehouseReducer.warehouses);
  // APIレスポンスが { results: [...] } 形式の場合に備えて分岐
  const warehouseStocksData = useSelector(state => state.warehouseReducer.warehouseStocks) || [];
  const warehouseStocks = Array.isArray(warehouseStocksData)
    ? warehouseStocksData
    : warehouseStocksData.results || [];

  useEffect(() => {
    if (selectedWarehouseId) {
      dispatch(fetchWarehouseStock(selectedWarehouseId));
    }
  }, [selectedWarehouseId, dispatch]);

  useEffect(() => {
    // 型変換して比較（selectedGoodId は文字列のため）
    const selectedGood = warehouseStocks.find(g => g.goods_id === Number(selectedGoodId));
    if (selectedGood) {
      setInitialWarehouseQuantity(selectedGood.stock_count);
      setVehicleQuantity(0);
    }
  }, [selectedGoodId, warehouseStocks]);

  const handleNextStep = () => {
    if (selectedWarehouseId) setStep(2);
  };

  const handleAddItem = () => {
    if (!selectedGoodId || vehicleQuantity < 1) return;

    dispatch(addVehicleStock({
      delivery_vehicle_id: selectedDeliveryVehicleId,
      warehouse_id: Number(selectedWarehouseId),
      goods_id: Number(selectedGoodId),
      stock_count: vehicleQuantity,
    }));

    // リセット処理
    setStep(1);
    setSelectedWarehouseId('');
    setSelectedGoodId('');
    setInitialWarehouseQuantity(0);
    setVehicleQuantity(0);
    toggle();
  };

  // 右矢印: 配送車数量を増加（初期在庫数を超えない）
  const increaseStock = () => {
    if (vehicleQuantity < initialWarehouseQuantity) {
      setVehicleQuantity(vehicleQuantity + 1);
    }
  };

  // 左矢印: 配送車数量を減少（0未満にならない）
  const decreaseStock = () => {
    if (vehicleQuantity > 0) {
      setVehicleQuantity(vehicleQuantity - 1);
    }
  };

  // 現在の倉庫在庫 = 初期在庫 - 配送車へ移動した数量
  const availableWarehouseStock = initialWarehouseQuantity - vehicleQuantity;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {step === 1 ? '倉庫を選択' : '商品と数量を選択'}
      </ModalHeader>
      <ModalBody>
        {step === 1 && (
          <FormGroup>
            <Label>倉庫選択</Label>
            <Input
              type="select"
              value={selectedWarehouseId}
              onChange={e => setSelectedWarehouseId(e.target.value)}
            >
              <option value="">倉庫を選択してください</option>
              {warehouses.map(warehouse => (
                <option key={warehouse.warehouse_id} value={warehouse.warehouse_id}>
                  {warehouse.warehouse_code} - {warehouse.address}
                </option>
              ))}
            </Input>
          </FormGroup>
        )}
        {step === 2 && (
          <>
            <FormGroup>
              <Label>商品選択</Label>
              <Input
                type="select"
                value={selectedGoodId}
                onChange={e => setSelectedGoodId(e.target.value)}
              >
                <option value="">商品を選択してください</option>
                {warehouseStocks.map(good => (
                  <option key={good.goods_id} value={good.goods_id}>
                    {good.goods_no} - {good.goods_name} (在庫: {good.stock_count})
                  </option>
                ))}
              </Input>
            </FormGroup>
            {selectedGoodId && (
              <FormGroup style={{ textAlign: 'center' }}>
                <Label>数量移動</Label>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div>
                    <Label>倉庫数量</Label>
                    <Input
                      type="number"
                      value={availableWarehouseStock}
                      readOnly
                      style={{ width: '80px', textAlign: 'center' }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', margin: '0 15px' }}>
                    <Button color="primary" onClick={increaseStock} style={{ marginBottom: '5px' }}>
                      <FaArrowRight />
                    </Button>
                    <Button color="primary" onClick={decreaseStock}>
                      <FaArrowLeft />
                    </Button>
                  </div>
                  <div>
                    <Label>配送車数量</Label>
                    <Input
                      type="number"
                      value={vehicleQuantity}
                      onChange={e => {
                        const num = Number(e.target.value);
                        if (num <= initialWarehouseQuantity && num >= 0) {
                          setVehicleQuantity(num);
                        }
                      }}
                      style={{ width: '80px', textAlign: 'center' }}
                    />
                  </div>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <Label>初期在庫: {initialWarehouseQuantity}</Label>
                </div>
              </FormGroup>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>キャンセル</Button>
        {step === 1 && <Button color="primary" onClick={handleNextStep}>次へ</Button>}
        {step === 2 && <Button color="primary" onClick={handleAddItem}>追加</Button>}
      </ModalFooter>
    </Modal>
  );
};

export default AddItemVehicleStockModal;
